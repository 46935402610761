
.greeting-bg2{
    background-image: url(/images/sub/about/greeting/greeting-img.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 160px;
}

@media (min-width:576px){
}
@media (min-width:768px){
    .greeting-bg2{
        height: 240px;
    }
}
@media (min-width:1024px){
    .greeting-bg{
        background-image: url(/images/sub/about/greeting/greeting-bg.png);
        background-repeat: no-repeat;
        background-position: right 70px bottom;
    }
    .greeting-bg2{
        height: 360px;
    }
}
@media (min-width:1200px){
}

